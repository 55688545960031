import React from "react";
import Bottom from "./Bottom";
import Top from "./Top";




function Footer() {
  return (
    <>
    <div class="">
      <footer class="text-white">
       <Top/>
       <Bottom/>
      </footer>
    </div>
    </>
  );
}

export default Footer;
